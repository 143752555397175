<template><div><h1 id="what-is-a-chrultrabook-and-who-is-this-documentation-for" tabindex="-1"><a class="header-anchor" href="#what-is-a-chrultrabook-and-who-is-this-documentation-for"><span>What is a chrultrabook and who is this documentation for?</span></a></h1>
<p>A chrultrabook is a modified Chromebook that is able to run Windows, Linux, and sometimes macOS by using MrChromebox’s coreboot firmware. The purpose of this site is to provide documentation that is detailed, user-friendly, and has up-to-date information about the hardware, software, and firmware on Chromebooks.</p>
<hr>
<h2 id="these-docs-focus-on-two-main-things" tabindex="-1"><a class="header-anchor" href="#these-docs-focus-on-two-main-things"><span>These docs focus on two main things</span></a></h2>
<ul>
<li>Installing Windows/Linux/macOS on your Chromebook.</li>
<li>Learning in-depth information about your Chromebook.</li>
</ul>
<p>Because of this, you are expected to read, learn, and use Google. <strong>This is NOT a one click procedure.</strong></p>
</div></template>


